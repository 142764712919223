body {
    background: #343332;
    font-size: 16px;
    line-height: 140%;
    font-family: 'Open Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: 'Open Sans', sans-serif;
    line-height: 140%;
}

h1 {
    font-size: 2rem;
}

h2 {
    font-size: 1.6rem;
}

h3 {
    font-size: 1.2rem;
}

a.btn,
button.btn {
    background-color: #ccc;
    padding: 5px 10px;
    font-size: 0.8rem;
    font-family: 'Open Sans';
    cursor: pointer;
    display: inline-block;
    color: #000;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    transition: 250ms ease-in-out all;
    appearance: none;
    border: none;
    box-shadow: none;
    outline: none;
    line-height: 140%;
}

a.btn:hover,
button.btn {
    background: #ccc;
}

a.btn-blue,
button.btn-blue {
    color: #fff;
    background: rgb(47, 88, 233);

    &:hover {
        background: darken(rgb(47, 88, 233), 10);
    }
}


a.btn-large,
button.btn-large {
    padding: 10px 20px;
    font-size: 1rem;
    line-height: 120%;
}

a.btn-gold,
button.btn-gold {
    color: #000;
    background: rgb(248, 222, 3);

    &:hover {
        background: darken(rgb(248, 222, 3), 10);
    }
}

a.btn-dark-gray,
button.btn-dark-gray {
    background: #444;
    color: #fff;
    cursor: pointer;

    &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &:hover {
        background: #222;
    }
}


.mapboxgl-popup-content {
    padding: 0 !important;
}

.location-popup {
    width: 350px;
    max-width: 75vw;

    .top-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #eee;
        padding: 2px 5px;
        margin-bottom: 10px;
    }

    .circle {
        display: block;
        height: 20px;
        width: 20px;
        border-radius: 100%;
    }

    .category-name {
        text-transform: uppercase;
        font-size: 1rem;
    }

    .actions {
        text-align: center;
        padding: 0 0 20px 0;

        a.btn {
            margin: 0 10px;
            padding: 7px 10px;
        }
    }

    .close-btn {
        display: block;
        position: relative;
        height: 35px;
        width: 35px;
        appearance: none;
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        transition: 350ms ease-in-out all;
        padding: 0;

        .close {
            display: block;
            //transform: rotate(45deg);
            position: relative;
            width: 35px;
            height: 35px;

            &:before,
            &:after {
                content: ' ';
                display: block;
                position: absolute;
                height: 2px;
                left: 25%;
                right: 25%;
                top: 50%;
                margin-top: -1px;
                background: #000;
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }
        }

        &:hover {
            background: #ddd;
        }
    }
}

.location-info {
    padding: 15px;

    h2 {
        font-size: 1.3rem;
        letter-spacing: 0.5px;
        margin: 0;
        line-height: 140%;
    }

    .address {
        font-size: 1.1rem;
        line-height: 140%;
    }
}

.legend {
    position: absolute;
    bottom: 4%;
    right: 1%;
    padding: 20px;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0.8;
    border-radius: 5px;

    h2 {
        color: #fff;
        text-align: center;
        margin: 0 0 20px 0;
        padding: 0;
        font-size: 1rem;
    }

    .category {
        color: #fff;
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        font-size: 0.8rem;
    }

    .circle {
        width: 15px;
        height: 15px;
        display: inline-block;
        border-radius: 50%;
        margin-right: 5px;
    }
}

.welcome {
    position: absolute;
    top: 1%;
    left: 1%;
    padding: 20px;
    max-width: 350px;
    background: rgba(0, 0, 0, 0.5) url('https://www.cincychilimap.com/welcome-bg.png') no-repeat bottom center;
    z-index: 99;
    background-size: contain;
    border-radius: 5px;

    button.welcome-toggle {
        appearance: none;
        background: none;
        border: none;
        outline: none; 
        box-shadow: none;
        color: #fff;
        float: right;
        height: 44px;
        width: 44px;
        text-align: center;
        line-height: 44px;
        cursor: pointer;
    }

    .welcome-inner {
        max-height: 270px;
        transition: 250ms ease-in-out max-height;
    }

    &.no-bg {
        background: rgba(0, 0, 0, 0.5);
    }

    &.collapsed {

        h1 {
            margin-bottom: 0;
        }

        .welcome-inner {
            max-height: 0;
            overflow: hidden;
        }
    }

    abbr {
        border-bottom: none;
        text-decoration: none;
        background: none;
    }

    .description-small {
        font-size: 0.8rem;
    }

    h1 {
        color: #fff;
        margin: 0 0 20px 0;
        padding: 0;
    }

    p,
    a {
        color: #fff;
    }

    .share-icons a > div {
        border-radius: 0;
        opacity: 0.85;
        padding: 0.5em;
    }
}

.suggest-location-wrapper {
    width: 850px;
    max-width: 90%;
    margin: 10px auto;

    h2 {
        color: #fff;
        text-align: center;
    }

    p {
        color: #fff;
        line-height: 160%;
        font-size: 0.9rem;
        text-align: center;
    }
}

.cryptedmail {
    appearance: none;
    background: none;
    border: none;
    font-family: 'Open Sans', sans-serif;
    padding: 0;
    margin: 0;
    outline: none;
    cursor: pointer;
}

.cryptedmail:after {
    content: attr(data-name) "@" attr(data-domain) "." attr(data-tld); 
    color: #fff;
    display: inline-block;
    margin-left: 5px;
    text-decoration: underline;
  }

.suggest-location-form-wrapper {
    width: 500px;
    max-width: 90%;
    margin: 10px auto;

    h2 {
        color: #fff;
        text-align: center;
    }

    p {
        color: #fff;
        line-height: 160%;
        font-size: 0.9rem;
    }

    .form-group {
        margin-bottom: 5%;
    }

    label {
        display: block;
        padding-bottom: 1%;
        color: #fff;
    }

    input,
    select,
    textarea {
        background: #1B1A19;
        padding: 2%;
        border: none;
        color: #eee;
        width: 100%;
        outline: none;
        font-size: 1rem;
        box-sizing: border-box;
        transition: 250ms ease-in-out all;

        &:focus {
            background: darken(#1B1A19, 2);
        }
    }

    .form-actions {
        text-align: center;

        button.btn {
            margin: 0 20px;
        }
    }
}

.form-error,
.form-success {
    line-height: 140%;
    margin: auto;
    color: #d82d2d;
    text-align: center;
    font-size: 1.25rem;
    font-weight: bold;
    padding: 10px 20px;
}

.form-success {
    color: #0e871e;
    text-align: center;
}

.form-inline-error {
    display: block;
    color: #d82d2d;
    padding: 5px 0;
}

.clearfix {
    clear: both;
}

.legend-toggle {
    display: none;
}

.location-listing {
    width: 500px;
    max-width: 90%;
    margin: 10px auto;

    .loading {
        color: #fff;
        text-align: center;
        font-size: 2rem;
        padding-bottom: 25px;
        max-height: 60px;
        overflow: hidden;
        transition: 250ms ease-in-out all;

        &.hide {
            padding: 0;
            max-height: 0;
        }
    }

    h1, h2 {
        color: #fff;
    }

    h1 {
        margin-bottom: 0;
    }    

    header {
        margin-bottom: 10%;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        list-style-type: none;
        display: block;
        margin: 0 0 5% 0;
        padding: 0 0 5% 0;
        border-bottom: 1px solid #444;

        h2 {
            margin: 0;
            padding: 0 0 2% 0; 
            line-height: 140%;
        }

        .address {
            color: #fff;
        }
    }

    .location-listing-footer {
        text-align: center;
    }

    .visited {
        padding: 10px 0 0 0;
        color: #0e871e;
    }

    .actions {
        padding: 10px 0 0 0;

        .btn {
            margin-right: 10px;
        }
    }

    p {
        color: #fff;
    }

}

@media screen and (max-width: 767px) {

    .legend-toggle {
        display: block;
        -webkit-appearance: none;
        appearance: none;
        color: #fff;
        background: rgba(0,0,0,0.75);
        outline: none;
        border: none;
        cursor: pointer;
        text-align: center;
        width: 100%;
        box-sizing: border-box;
        padding: 10px 20px;
        border-radius: 5px;

        &.legend-shown {
            border-radius: 0;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
        }
    }

    .legend-content {
        max-height: 0;
        overflow: hidden;
        transition: 250ms ease-in-out all;
        box-sizing: border-box;
        padding: 0 20px;
    }

    .legend-content.resp-show {
        max-height: 300px;
        padding: 20px;
    }

    .welcome {
        padding: 10px;
        position: absolute;
        left: 45px;
        right: 45px;
        top: 1%;
        max-width: 325px;
        margin: auto;
        text-align: center;
        background: rgba(0,0,0,0.75);

        &.no-bg {
            background: rgba(0,0,0,0.75);
        }

        h1 {
            font-size: 1.5rem;
            margin-bottom: 0;
        }

        button.welcome-toggle {
            height: 33px;
            width: 33px;
            line-height: 33px;
        }

        p {
            font-size: 0.85rem;
        }
    }

    .legend {
        padding: 0;
        left: 1%;
        right: 1%;
        bottom: 1%;
        width: 200px;
        margin: auto;
        background: rgba(0,0,0,0.75);
        opacity: 1;
    }

    .welcome .description-small, .welcome .description {
        display: none;
    }

    .location-info {
        padding: 0 15px;

        h2 {
            font-size: 1.2rem;
        } 

        .address {
            font-size: 1rem;
        }
    }

    .location-popup .actions a.btn {
        padding: 7px;
    }
}

@media screen and (max-width: 500px) {
    .location-popup .actions > a.btn {
        display: block;
        margin: 0 15px 7.5px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .share-icons a > div {
        padding: 0.1em;
    }

    // .share-icons {
    //     display: none;
    // }
}